@import '../../node_modules/antd/dist/reset.css';

:root{
  --main-font: "Lato";
  --second-font: 'Paytone One';
  --main-color: #141645;
  --second-color: #E77087;
  --fill-color: #E3E4E8;
  --fill-second-color: white;
  ---border-radius: 10px;
}

.App {
}

body {
  font-family: var(--main-font), sans-serif;
  /*font-family: var(--second-font), sans-serif;*/
  padding: 20px;
  /*width: 500px !important;*/
  margin: 0 auto !important;
  color: #141645;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}
input {outline: 0 !important;}


.header_search{
  color: #141645;
}

.header_search div{
  display: flex;
  position: relative;
}

.header_caption{
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  font-family: var(--second-font), sans-serif;
  color: var(--main-color);

}
.header_subcaption{
  text-align: center;
}

.header_search .word_search{
  height: 60px;
  border: 2px solid #E3E4E8;
  border-radius: var(---border-radius);
  padding: 10px;
  width: 100%;
}

.header_search .word_search:focus,
.header_search .word_search:focus-visible{
  border: 1px solid var(--main-color);
}

.header_search .ant-input-prefix{
  opacity: 0.2;
  font-size: 30px;
}

.block_style {
  background-color: white;
  border-radius: var(---border-radius);
  padding: 20px;
  position: relative;
}

.block_style__color {
  background-color: var(--main-color);
  color: white;
}

.block_style__color a {
  color: white;
}

.block_style_white{
  -webkit-box-shadow: 0px 13px 70px -47px rgba(20, 22, 69, 1);
  -moz-box-shadow: 0px 13px 70px -47px rgba(20, 22, 69, 1);
  box-shadow: 0px 13px 70px -47px rgba(20, 22, 69, 1);
}

.current_word{
  font-size: 50px;
  display: flex;
  justify-content: space-between;
align-items: center;

}

.word_phonetic{
  display: flex;
  align-Items: center;
}
.word_phonetic__speek{
  width: 40px;
  height: 40px;
  border-radius: var(---border-radius);
  background-color: var(--second-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;

}
.defenitions{
  font-weight: bold;
  color: #141645;
  font-size: 30px;
}
.part_of_speech{
  font-size: 26px;
  color: #999AA9;
}

.definition{
  margin-bottom: 10px;
}

.definition_num{
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.numeric{
  color: #E77087;
  margin-right: 10px;
  width: 10px;
}

.sentence{
  margin-left: 20px;
  font-weight: 600;
}

.footer{
  text-align: center;
}

.block_photo{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 10px;
  column-gap: 20px;
}